import styled from "styled-components";

 export const AboutContainer = styled.div` 
    height: 800px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #184832;  
    @media screen and (max-width: 1480px)
    {
        transition: 0.8s all ease;
      height: 570px;
    }
    @media screen and (max-width: 1080px)
    {
        transition: 0.8s all ease;
      height: 775px;
    }
    @media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
      height: 675px;
    }
 `

 export const AboutWrapper = styled.div`
 
    width: 85%;
    height: 95%;
    display: flex;
    background: transparent;
    
    justify-content: center;
    align-items: center;
   
  
    @media screen and (max-width: 1080px)
    {
        transition: 0.8s all ease;
        justify-content: center;
        flex-direction: column;
        width: 80%;
        height: 90%;
    }
    @media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
      height: 85%;
    }
 `

export const CollectionContainer = styled.div`
width: 45%;
height: 85%;
display: flex;

justify-content: space-between;
align-items: center;
flex-direction: column;
@media screen and (max-width: 1080px)
{
    transition: 0.8s all ease;
    width: 80%;
    height: 50%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    width: 100%;
    height: 50%;
}

`

 export const CollectionTitle = styled.div`
 width: 100%;
 height: 15%;
 font-family: Modak;
 font-size: 65px;
 text-align: center;
 -webkit-text-stroke: 1px #184832;
 color: white;
 @media screen and (max-width: 1080px)
{
    transition: 0.8s all ease;
    font-size: 55px;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 40px;
}
 `

 export const ImageContainer = styled.div`
 width: 100%;
 height: 50%;

 `

 export const CollectionImage = styled.img`
 width: 100%;
 height: 100%;
 border-radius: 90%;
 justify-content: center;
 align-items: center;
 @media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    width: 100%;
    height: 85%;
}
 `

 export const CollectionDesc = styled.div`
 width: 80%;
 height: 20%;
 justify-content: center;
 align-items: center;
 font-family: Dosis;
 font-weight: bold;
 font-size: 25px;
 text-align: center;
 @media screen and (max-width: 1080px)
{
    transition: 0.8s all ease;
    font-size: 15px;
}
 `

 export const CollectionLink = styled.a`
 width: 35%;
 height: 50%;
 justify-content: center;
 align-items: center;
 border-radius: 90%;
 &:hover{
    box-shadow: 3px 3px 6px black;
 }
 @media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 50%;
  
}
@media screen and (max-width: 980px)
{
    transition: 0.8s all ease;
    width: 40%;

}
 `


 








