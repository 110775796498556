import React from 'react';
import { HalfContainer, SporesIcon, StakeContainer,  StakeTitle,  StakeWrapper, StakingBtn, StakingInfo, StakingLink } from './StakeElements';
import { StakingArea } from "../Navbar/NavbarElements";
import Spores from '../../img/Stake/Spores.png'

const Stake = () => {
  return (
    <StakeContainer {...StakingArea}>
        <StakeWrapper>
            <HalfContainer>
                <SporesIcon src={Spores}/>
            </HalfContainer>
            <HalfContainer>
                <StakeTitle>$PORES</StakeTitle>
                <StakingInfo>
                    <p>$PORES is a utility token earned by staking your mushies and can be used in mushies raffle house to enter to win 1/1 art upgrades for your mushies done by our Artist of the Week!</p>
                    <h1>
                        MAGIC MUSHIES = 5 $PORES/day
                    </h1>
                    <h1>
                        MYTHIC MUSHIES = 10 $PORES/day
                    </h1>
                    <StakingLink href="https://staking.magicmushies.io">
                    <StakingBtn>STAKE</StakingBtn>
                    </StakingLink>
                   
                </StakingInfo>
            </HalfContainer>
       
        </StakeWrapper>
    </StakeContainer>
)
}

export default Stake
