import './App.css';
import Team from './Components/Team/Team';
import { HeroContainer, HeroImage } from './Components/Hero/HeroElements';
import Hero from '../src/img/Hero/MUSHIEKINGDOM.png';
import AboutSection from './Components/About/About';
import AccordionC from './Components/FAQ/AccordianElements';
import Footer from './Components/Footer/Footer';
import Stake from './Components/Staking/Stake';
import Roadmap from './Components/Roadmap/Roadmap';
import Sidebar from './Components/Sidebar';
import {Nav, NavbarContainer, MobileIcon, NavMenu, NavItem, NavLinks, Bav} from './Components/Navbar/NavbarElements'
import { FaBars } from 'react-icons/fa'
import { useEffect, useState } from "react";
import { MobileAboutArea } from './Components/Navbar/NavbarElements';
import Raffle from './Components/Raffle/Raffle';
import Artist from './Components/Artist/Artist';


function App() {
  
const[isOpen, setIsOpen] = useState(false)
const[clicked, setClicked] = useState(false)

 const toggle = () => {
     setIsOpen(!isOpen)
}

  return (
    <main>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Bav {...MobileAboutArea}/>
      <Nav> 
            <NavbarContainer>

                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>

                <NavMenu>
                <NavItem>
                    <NavLinks to ='about' smooth={true} duration={500} spy={true} exact='true'>ABOUT</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='road' smooth={true} duration={500} spy={true} exact='true'>ROADMAP</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='staking' smooth={true} duration={500} spy={true} exact='true'>STAKING</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='raffles' smooth={true} duration={500} spy={true} exact='true'>RAFFLES</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='artist' smooth={true} duration={500} spy={true} exact='true'>ARTISTS</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='team' smooth={true} duration={500} spy={true} exact='true'>TEAM</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='faq' smooth={true} duration={500} spy={true} exact='true'>FAQ</NavLinks>
                    </NavItem>
                </NavMenu>

            </NavbarContainer>
        </Nav>
      <HeroContainer >
        <HeroImage src={Hero}/>
    
      </HeroContainer>


      <AboutSection/>

      <Roadmap/>

      <Stake/>

      <Raffle/>

      <Artist/>

      <Team/>

      <AccordionC/>

      <Footer/>
      

    </main>
  );
}

export default App;
