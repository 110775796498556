import styled from "styled-components";
import LightBG from '../../img/Backgrounds/LightBG3.png'

export const RaffleContainer = styled.div`
height: 1000px;
width: 100%;
background: #1a6930;
background-image: url(${LightBG});
color: white;
display: flex;
justify-content: center;
align-items: center;
@media screen and (max-width: 1480px)
{
    transition: 0.8s all ease;
    height: 800px;

}
@media screen and (max-width: 780px)
{
    transition: 0.8s all ease;
    height: 600px;

}
`
export const RaffleWrapper = styled.div`
height: 100%;
width: 90%;
display: flex;
justify-content: center;
align-items: center;
border-bottom: 10px solid white;
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    flex-direction: column;

}

`

export const HalfContainer = styled.div`
width: 60%;
height: 95%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 1%;
flex-direction: column;

@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 80%;

}
@media screen and (max-width: 1380px)
{
    transition: 0.8s all ease;
    width: 100%;

}
`

export const RaffleGif = styled.img`
display: flex;
justify-content: center;
align-items: center;
height: 60%;
width: 70%;
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    width: 90%;
    height: 45%;

}
@media screen and (max-width: 390px)
{
    transition: 0.8s all ease;
    width: 90%;
    height: 40%;

}
`

export const RaffleBtn = styled.button`
height: 100%;
width: 100%;
background: #184832;
border-radius: 25px;
margin-top: 5%;

font-family: Modak;
color: white;
font-size: 45px;
-webkit-text-stroke: 0.5px #1a6930;
&:hover{
font-size: 46px;
box-shadow: 2px 2px 4px black;
cursor: pointer;

}
@media screen and (max-width: 1380px)
{
    transition: 0.8s all ease;
    font-size: 40px;

}
@media screen and (max-width: 1080px)
{
    transition: 0.8s all ease;
    font-size: 30px;

}
@media screen and (max-width: 780px)
{
    transition: 0.8s all ease;
    font-size: 25px;
    border-radius: 15px;

}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 18px;
    &:hover{
font-size: 19px;
box-shadow: 2px 2px 4px black;
cursor: pointer;

}
}

`

export const RaffleLink = styled.a`
width: 60%;
height: 10%;
@media screen and (max-width: 780px)
{
    transition: 0.8s all ease;
    width: 70%;
    height: 15%;


}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    width: 85%;
    height: 12%;


}
`
