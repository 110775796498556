import styled from "styled-components";
import LightBG2 from '../../img/Backgrounds/LightBG2.png'
import RoadmapImg2 from '../../img/Roadmap/roadmap2.png'

export const RoadContainer = styled.div`
display: flex;
height: 1100px;
width: 100%;
justify-content: center;
align-items: center;
position: relative;
background: #1a6930;
background-image: url(${LightBG2});
color: white;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    height: 1000px;
}
@media screen and (max-width: 1080px)
{
    transition: 0.8s all ease;
    height: 900px;
}
@media screen and (max-width: 880px)
{
    transition: 0.8s all ease;
    height: 600px;
}
@media screen and (max-width: 550px)
{
    transition: 0.8s all ease;
    height: 450px;
}
`

export const RoadWrapper = styled.div`
display: flex;
height: 100%;
width: 90%;
justify-content: center;
align-items: center;
position: absolute;
border-bottom: 5px solid white;
border-top: 5px solid white;
z-index: 2;
flex-direction: column;

@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    width: 95%;
}
`

export const RoadmapPic = styled.img`
display: flex;
height: 95%;
width: 73%;
border-radius: 2%;
box-shadow: 5px 5px 10px black;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 90%;
}
@media screen and (max-width: 780px)
{
    transition: 0.8s all ease;
    width: 100%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    height: 75%;
}

`

export const RoadmapSection = styled.div`
width: 90%;
height: 85%;
display: flex;
justify-content: center;
align-items: center;
`

export const RoadmapTitle = styled.div`
height: 10%;
width: 66%;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
font-family: Modak;
font-size: 75px;
color: white;
background: #184832;
border-radius: 20px;
box-shadow: 5px 5px 10px black;
-webkit-text-stroke: 1px #1a6930;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 80%;
}
@media screen and (max-width: 1080px)
{
    transition: 0.8s all ease;
    font-size: 55px;
}
@media screen and (max-width: 880px)
{
    transition: 0.8s all ease;
    font-size: 45px;
    width: 90%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 35px;
    border-radius: 10px;
}
`









