export const Data = [
    {
        question: 'What is Mushie Kingdom?',
        answer: 'Mushie Kingdom is made up of two collections on the Solana network. Magic Mushies is a collection of 4,444 and Mythic Mushies is a collection of 666' 
    },
    {
        question: 'When did Magic Mushies mint?',
        answer: '4,444 Magic Mushies were minted January 5th at a price of 0.44 Sol per mint.' 
    },
    {
        question: 'When did Mythic Mushies mint?',
        answer: '666 Mythic Mushies were minted April 25th at a price of 1.5 SOL per mint.' 
    },
    {
        question: 'How do holders get $PORES?',
        answer: 'Our Staking and Tokenomics programs allow holders to currently receive 5 $PORES per day when staking Magic Mushies, 10 $PORES per day when staking Mythic Mushies. Mushies can be staked at staking.magicmushies.io' 
    },

    {
        question: 'What is the future for this project?',
        answer: 'Mushies will continue to spotlight 1/1 artists in the space by providing Art Upgrades by a different artist each week making both collections incredibly unique, unlike anything else in the space. The team will work with MushieDAO continuously searching for more ways to add utility to $PORES. ' 
    },
    {
        question: 'How do I upgrade my Mushies?',
        answer: 'Each week there will be raffles held in $PORES on raffles.magicmushies.io in order to figure out who is eligible for their Magic Mushie to be upgraded by the current weeks artist! Mythic Mushies enter themselves into a queue in Discord and can receive a completely unique art upgrade based on whatever you wish it to be.' 
    },
];