import styled from "styled-components";

export const StakeContainer = styled.div`
display: flex;
position: relative;
width: 100%;
height: 850px;
background: #184832;
justify-content: center;
align-items: center;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    height: 900px;
}
@media screen and (max-width: 1380px)
{
    transition: 0.8s all ease;
    height: 750px;
}
@media screen and (max-width: 880px)
{
    transition: 0.8s all ease;
    height: 700px;
}
@media screen and (max-width: 780px)
{
    transition: 0.8s all ease;
    height: 1050px;
}
`

export const StakeWrapper = styled.div`
height: 100%;
width: 90%;
display: flex;
justify-content: center;
align-items: center;
border-bottom: 5px solid white;
z-index: 2;

@media screen and (max-width: 780px)
{
    transition: 0.8s all ease;
    flex-direction: column;
}
`

export const HalfContainer = styled.div`
width: 45%;
height: 95%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media screen and (max-width: 780px)
{
    transition: 0.8s all ease;
    width: 95%;
    height: 48%;
}
`

export const SporesIcon = styled.img`

height: 60%;
width: 60%;
justify-content: center;
align-items: center;
display: flex;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    height: 50%;
    width: 60%;
}
@media screen and (max-width: 1480px)
{
    transition: 0.8s all ease;
    height: 50%;
    width: 80%;
}
@media screen and (max-width: 880px)
{
    transition: 0.8s all ease;
   height: 40%;
   width: 70%;
}
@media screen and (max-width: 780px)
{
    transition: 0.8s all ease;
   height: 80%;
   width: 80%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
   height: 60%;
   width: 80%;
}
@media screen and (max-width: 390px)
{
    transition: 0.8s all ease;
   height: 50%;
   width: 80%;
}

`
export const StakeTitle = styled.div`
width: 57%;
height: 20%;
font-size: 65px;
font-family: Modak;
text-align: center;
align-items: center;
justify-content: center;
display: flex;
margin: 1%;
color: white;
background: #1a6930;
border-radius: 25px;
box-shadow: 5px 5px 10px black;
-webkit-text-stroke: 1px #184832;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 80%;
}
@media screen and (max-width: 990px)
{
    transition: 0.8s all ease;
    font-size: 50px;
}
@media screen and (max-width: 780px)
{
    transition: 0.8s all ease;
    font-size: 40px;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    width: 93%;
}
`

export const StakingInfo = styled.div`
width: 55%;
height: 70%;
display: flex;
font-family: Marker;
font-size: 25px;
text-align: center;
align-items: center;
justify-content: center;
background: #1a6930;
border-radius: 25px;
box-shadow: 5px 5px 10px black;
margin: 1%;
color: white;
padding: 2%;
flex-direction: column;
p{
    margin: 2%;
    margin-bottom: 3%;
}
h1{
    font-family: Modak;
    font-size: 33px;
    -webkit-text-stroke: 0.5px #184832;
    margin:  1%;
    margin-bottom: 2%;
}
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 77%;
    h1{
        font-size: 28px;
        margin-top: 2%;
        margin-bottom: 5%;
    }
    p{
        margin-bottom: 7%;
    }
}
@media screen and (max-width: 1380px)
{
    transition: 0.8s all ease;
    width: 77%;
    h1{
        font-size: 20px;
        margin-top: 2%;
        margin-bottom: 5%;
    }
    p{
        margin-bottom: 7%;
        font-size: 20px;
    }
}
@media screen and (max-width: 990px)
{
    transition: 0.8s all ease;
    flex-direction: column;
    p{
        font-size: 17px;
    }
    h1{
        font-size: 17px;
    }
}
@media screen and (max-width: 780px)
{
    transition: 0.8s all ease;
    flex-direction: column;
    p{
        font-size: 15px;
    }
    h1{
        font-size: 15px;
    }
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    width: 90%;
}
`

export const StakingBtn = styled.button`
height: 100%;
width: 100%;
background: #184832;
border-radius: 25px;
margin-top: 5%;

font-family: Modak;
color: white;
font-size: 45px;
&:hover{
font-size: 50px;
box-shadow: 2px 2px 4px black;
cursor: pointer;
}
@media screen and (max-width: 880px)
{
    transition: 0.8s all ease;
    font-size: 35px;
    border-radius: 15px;
}
`

export const StakingLink = styled.a`
height: 20%;
width: 50%;
@media screen and (max-width: 990px)
{
    transition: 0.8s all ease;
    width: 70%;
    height: 20%;
}

`



