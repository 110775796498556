import React from 'react'
import { AboutContainer, AboutWrapper, CollectionContainer, CollectionDesc, CollectionImage, CollectionLink, CollectionTitle } from './AboutElements'


import Magic from '../../img/about/Magic.png'
import Mythic from '../../img/about/Mythic.png'

import { AboutArea } from '../Navbar/NavbarElements'



const AboutSection = () => {
    return (
        <AboutContainer {...AboutArea}>
            <AboutWrapper>
                <CollectionContainer>
                <CollectionTitle>MAGIC</CollectionTitle>
                <CollectionLink href="https://magiceden.io/marketplace/magic_mushies">
                <CollectionImage src={Magic}/>
                </CollectionLink>
               <CollectionDesc>4444 Magic Mushies trippin' to put the emphasis back on creativity & community.</CollectionDesc>
                </CollectionContainer>

                <CollectionContainer>
                <CollectionTitle>MYTHIC</CollectionTitle>
                <CollectionLink href="https://magiceden.io/marketplace/mythic_mushies">
                <CollectionImage src={Mythic}/>
                </CollectionLink>
                <CollectionDesc>666 Mythic Mushies who have ascended to a higher plane. Alpha, Community, Education, Infinity.</CollectionDesc>
                </CollectionContainer>
     
            </AboutWrapper>
            </AboutContainer>
    )
}

export default AboutSection
