import React from 'react'
import { RaffleArea } from '../Navbar/NavbarElements'
import { HalfContainer, RaffleBtn, RaffleContainer, RaffleGif, RaffleLink, RaffleWrapper, SubContainer } from './RaffleElements'
import RafflesGif from '../../img/Raffle/mushieraffles.gif'

const Raffle = () => {
  return (
    <RaffleContainer {...RaffleArea}>
        <RaffleWrapper>
        <HalfContainer>
            <RaffleGif src={RafflesGif}/>
            <RaffleLink href="https://raffles.magicmushies.io">
                <RaffleBtn>ENTER THE RAFFLE HOUSE</RaffleBtn>
            </RaffleLink>
        </HalfContainer>
   

        </RaffleWrapper>

    </RaffleContainer>
  )
}

export default Raffle