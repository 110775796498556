import styled from "styled-components";

export const ArtistContainer = styled.div`
height: 900px;
width: 100%;
display: flex;
position: relative;
color: white;
background: #184832;
justify-content: center;
align-items: center;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    height: 950px;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    height: 850px;
}
`
export const ArtistWrapper = styled.div`
height: 100%;
width: 90%;
display: flex;
justify-content: center;
align-items: center;
border-bottom: 5px solid white;
z-index: 2;

@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    flex-direction: column;
}
`

export const HalfContainer = styled.div`
width: 70%;
height: 95%;
display: flex;
justify-content: center;
align-items: center;

margin: 1%;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 100%;
    height: 60%;
}
`
export const QuarterContainer = styled.div`
width: 30%;
height: 95%;
display: flex;
justify-content: center;
align-items: center;

margin: 1%;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 100%;
    height: 40%;
}
`

export const ImageContainer = styled.div`
height: 80%;
width: 60%;
display: flex;
justify-content: center;
align-items: center;

@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 50%;
    height: 100%;
}
`

export const ArtistOTW = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

`

export const SectionTitle = styled.div`
width: 60%;
height: 10%;
font-family: Modak;
color: white;
font-size: 65px;
justify-content: center;
align-items: center;
text-align: center;


background: #1a6930;
border-radius: 25px;
box-shadow: 3px 3px 6px black;
-webkit-text-stroke: 1px #184832;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 70%;
    font-size: 55px;
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    font-size: 45px;
    border-radius: 15px;
    height: 12%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 30px;
    border-radius: 15px;
    width: 100%;
    height: 10%;
    padding-top: 3%;
}

`
export const ArtistSection = styled.div`
height: 80%;
width: 75%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
   width: 100%;
}
`

export const ArtistInfo = styled.div`
height: 70%;
width: 30%;
display: flex;
justify-content: center;
flex-direction: column;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 50%;
}

`
export const ArtistLink = styled.a`
height: 90%;
width: 90%;
display: flex;
justify-content: center;
align-items: center;
`

export const ArtistImg = styled.img`
height: 90%;
width: 90%;
border-radius: 20%;
box-shadow: 3px 3px 6px black;
border: 5px solid #1a6930;
&:hover{
   cursor: pointer;
    
 }
 @media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    height: 70%
}
@media screen and (max-width: 880px)
{
    transition: 0.8s all ease;
    height: 60%
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    height: 40%;
    margin-right: 10%;
  
}
`

export const ArtistName = styled.div`
width: 80%;
height: 15%;
display: flex;
font-family: Modak;
font-size: 55px;
color: white;
-webkit-text-stroke: 1px #184832;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    margin-bottom: 5%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 45px;
}
`

export const ArtistDesc = styled.div`
width: 80%;
height: 10%;
display: flex ;
font-family: Dosis;
font-weight: bold;
color: white;
font-size: 25px;
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    margin-top: 10%;
    font-size: 15px;
    width: 100%;
}
`

export const ArtTransform = styled.div`
width: 70%;
height: 50%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
`

export const ArtTransformTop = styled.div`
width: 100%;
height: 50%;
display: flex;
justify-content: center;
flex-direction: column;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

`
export const ArtTransformBot = styled.div`
width: 100%;
height: 50%;
display: flex;
justify-content: center;
align-items: flex-end;
flex-direction: column;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
`

export const ArtTitle = styled.div`
width: 100%;
height: 10%;
display: flex;
font-family: Modak;
font-size: 45px;
color: white;


margin: 3%;
margin-left: 17%;
-webkit-text-stroke: 1px #184832;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    margin-left: 12%;
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    margin-left: 0%;
    justify-content: center;
    align-items: center;
    text-align: center;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 25px;
}
`
export const ArtTitleBot = styled.div`
width: 100%;
height: 10%;
display: flex;
font-family: Modak;
font-size: 45px;
color: white;
justify-content: flex-end;
text-align: flex-end;

margin: 3%;
margin-right: 20%;
-webkit-text-stroke: 1px #184832;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    margin-right: 15%;
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    margin-right: 0%;
    justify-content: center;
    align-items: center;
    text-align: center;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 25px;
}
`

export const ArtImages = styled.img`
width: 60%;
height: 70%;
display: flex;
justify-content: center;
align-items: center;
border-radius: 10%;
box-shadow: 3px 3px 6px black;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    height: 60%;
}
@media screen and (max-width: 1480px)
{
    transition: 0.8s all ease;
    height: 50%;
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    height: 50%;
    width: 40%;
}
@media screen and (max-width: 880px)
{
    transition: 0.8s all ease;
    height: 50%;
    width: 60%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    height: 50%;
    width: 95%;
}

`

export const UpgradeOTW = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    flex-direction: row;
    
}


`

