import React from 'react';
import {  PhaseDesc, PhaseTitle, RoadContainer, RoadmapPhase, RoadmapPic, RoadmapSection, RoadmapTitle, RoadWrapper, SectionTitle, TextSection} from './RoadElements';

import { RoadArea } from '../Navbar/NavbarElements';
import RoadmapImg from '../../img/Roadmap/roadmap.png'

const Roadmap = () => {
  return (
    <RoadContainer {...RoadArea}>
        <RoadWrapper>
         <RoadmapTitle>ROADMAP 2.0</RoadmapTitle>
         <RoadmapSection>
         <RoadmapPic src = {RoadmapImg}/>
         </RoadmapSection>
     
  
            

        </RoadWrapper>
    </RoadContainer>
  ) 
}

export default Roadmap;
