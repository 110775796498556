import React from 'react';
import { ArtImages, ArtistContainer, ArtistDesc, ArtistImg, ArtistInfo, ArtistLink, ArtistName, ArtistOTW, ArtistSection, ArtistWrapper, ArtTitle, ArtTransform, HalfContainer, ImageContainer, SectionTitle, TransformSection, UpgradeOTW, QuarterContainer, ArtTransformTop, ArtTransformBot, ArtTitleBot  } from './ArtistElements';
import { ArtistArea } from '../Navbar/NavbarElements';
import Kamea from '../../img/Artist/Kamea.png'
import KameaBefore from '../../img/Artist/KameaBefore.png'
import KameaAfter from '../../img/Artist/KameaAfter.png'

import Boin from '../../img/Artist/Boin.jpg'
import BoinBefore from '../../img/Artist/BoinBefore.png'
import BoinAfter from '../../img/Artist/BoinAfter.jpg'

import Tornado from '../../img/Artist/Tornado.jpg'
import TornadoBefore from '../../img/Artist/TornadoBefore.png'
import TornadoAfter from '../../img/Artist/TornadoAfter.png'

import Capi from '../../img/Artist/Capi.jpg'
import CapiBefore from '../../img/Artist/CapiBefore.png'
import CapiAfter from '../../img/Artist/CapiAfter.png'

import reg from '../../img/Artist/reg.jpg'
import regBefore from '../../img/Artist/regBefore.png'
import regAfter from '../../img/Artist/regAfter.jpg'


const Artist = () => {
  return (
   <ArtistContainer {...ArtistArea}>
       <ArtistWrapper>
        <HalfContainer>
        <ArtistOTW>
          <SectionTitle>ARTIST OF THE WEEK</SectionTitle>
          <ArtistSection>
          <ImageContainer>
            <ArtistLink href="https://twitter.com/regggiee_">
              <ArtistImg src = {reg}/>
            </ArtistLink>
          </ImageContainer>
          <ArtistInfo>
            <ArtistName>reg.art</ArtistName>
            <ArtistDesc>@REGGGIEE_</ArtistDesc>
          </ArtistInfo>
          </ArtistSection>
        
        </ArtistOTW>
        </HalfContainer>
      


        <QuarterContainer>
          <UpgradeOTW>
          <ArtTransformTop>
            <ArtImages src = {regBefore}/>
            <ArtTitle>BEFORE</ArtTitle>
          </ArtTransformTop>
          <ArtTransformBot>
          <ArtImages src = {regAfter}/>
          <ArtTitleBot>AFTER</ArtTitleBot>
          </ArtTransformBot>
          </UpgradeOTW>
  
        </QuarterContainer>


       </ArtistWrapper>
   </ArtistContainer>
  ) 
};

export default Artist;
