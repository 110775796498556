import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrap, SidebarLink, SidebarMenu } from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrap>
                <SidebarMenu>
                    <SidebarLink to="mobileabout" onClick={toggle}>
                        ABOUT
                    </SidebarLink>
                    <SidebarLink to="road" onClick={toggle}>
                        ROADMAP
                    </SidebarLink>
                    <SidebarLink to="staking" onClick={toggle}>
                        STAKING
                    </SidebarLink>
                    <SidebarLink to="raffles" onClick={toggle}>
                        RAFFLES
                    </SidebarLink>
                    <SidebarLink to="artist" onClick={toggle}>
                        ARTISTS
                    </SidebarLink>
                    <SidebarLink to="team" onClick={toggle}>
                        TEAM
                    </SidebarLink>
                    <SidebarLink to="faq" onClick={toggle}>
                        FAQ
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrap>
        </SidebarContainer>
    )
}

export default Sidebar;
